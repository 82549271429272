import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  
  const BlogsPage = lazy(() => import('../modifiedModules/apps/blogs/BlogsPage'))
  const ServicesPages = lazy(() => import('../modifiedModules/apps/services/ItemsPage'))

  const UsersPages = lazy(() => import('../modifiedModules/apps/users/ItemsPage'))
  const AdminUsersPages = lazy(() => import('../modifiedModules/apps/adminUsers/ItemsPage'))
  const CitiesPage = lazy(() => import('../modifiedModules/apps/cities/ItemsPage'))
  const CoursesPages = lazy(() => import('../modifiedModules/apps/courses/ItemsPage'))
  const CategoriesPage = lazy(() => import('../modifiedModules/apps/categories/ItemsPage'))
  const ProvidersPage = lazy(() => import('../modifiedModules/apps/providers/ItemsPage'))
  const NeighborhoodsPage = lazy(() => import('../modifiedModules/apps/neighborhoods/ItemsPage'))
  const OrdersPages = lazy(() => import('../modifiedModules/apps/orders/ItemsPage'))
  const AdvertisementsPage = lazy(() => import('../modifiedModules/apps/advertisements/ItemsPage'))
  const ProductsPage = lazy(() => import('../modifiedModules/apps/products/ItemsPage'))
  const GeneralSettingsPage = lazy(() => import('../modifiedModules/apps/generalSettings/GeneralSettingsPage'))
  const StaticPagesPage = lazy(() => import('../modifiedModules/apps/staticPages/StaticPagesPage'))
  const ContactUsPage = lazy(() => import('../modifiedModules/apps/contactUs/ContactUsPage'))
  const AvatarsPage = lazy(() => import('../modifiedModules/apps/avatars/ItemsPage'))
  const AvailableDatesPage = lazy(() => import('../modifiedModules/apps/availableDates/ItemsPage'))
  const CategoryVideosPage = lazy(() => import('../modifiedModules/apps/categoryVideos/ItemsPage'))
  const SlidersPage = lazy(() => import('../modifiedModules/apps/sliders/ItemsPage'))

  const LoginPage = lazy(() => import('../modifiedModules/LoginPage/LoginPage'))


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path='auth/*' element={<Navigate to='/dashboard' />} /> */}
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        {/* <Route
          path='login/*'
          element={
            <SuspensedView>
              <LoginPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/admin/users/*'
          element={
            <SuspensedView>
              <AdminUsersPages />
            </SuspensedView>
          }
        />
        <Route
          path='apps/users/*'
          element={
            <SuspensedView>
              <UsersPages />
            </SuspensedView>
          }
        />
        <Route
          path='apps/providers/*'
          element={
            <SuspensedView>
              <ProvidersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/orders/*'
          element={
            <SuspensedView>
              <OrdersPages />
            </SuspensedView>
          }
        />
        <Route
          path='apps/blogs/*'
          element={
            <SuspensedView>
              <BlogsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/advertisements/*'
          element={
            <SuspensedView>
              <AdvertisementsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/videos/*'
          element={
            <SuspensedView>
              <CategoryVideosPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/sliders/*'
          element={
            <SuspensedView>
              <SlidersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/cities/*'
          element={
            <SuspensedView>
              <CitiesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/neighborhoods/*'
          element={
            <SuspensedView>
              <NeighborhoodsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/categories/*'
          element={
            <SuspensedView>
              <CategoriesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/general-settings/*'
          element={
            <SuspensedView>
              <GeneralSettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/static-pages/*'
          element={
            <SuspensedView>
              <StaticPagesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/products/*'
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/contact-us/*'
          element={
            <SuspensedView>
              <ContactUsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/avatars/*'
          element={
            <SuspensedView>
              <AvatarsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/services/*'
          element={
            <SuspensedView>
              <ServicesPages />
            </SuspensedView>
          }
        />
        <Route
          path='apps/courses/*'
          element={
            <SuspensedView>
              <CoursesPages />
            </SuspensedView>
          }
        />
        <Route
          path='apps/available-dates/*'
          element={
            <SuspensedView>
              <AvailableDatesPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
